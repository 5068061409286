<script lang="ts" setup>
import type { Social } from "@/types";
const { t } = useT();
withDefaults(
	defineProps<{
		socials: Social[];
		theme?: "dark" | "light";
		locationTid?: string;
	}>(),
	{ theme: "light" }
);

const route = useRoute();
const storePromoOffer = () => {
	const { promoOffer } = route.query;
	if (promoOffer && !Array.isArray(promoOffer)) {
		localStorage.setItem("promoOffer", promoOffer);
	}
};
const handleClickBtn = (name: string) => {
	storePromoOffer();

	dispatchGAEvent({
		event: "click_button",
		button_name: name.toLowerCase(),
		location: "registration_window"
	});
};
</script>

<template>
	<div :class="['socials', theme]">
		<a v-for="social in socials" :key="social.id" :href="social.href">
			<AButton
				:class="`social social-${social.id}`"
				:data-tid="locationTid ? `${locationTid}-${social.name.toLowerCase()}-btn` : null"
				type="button"
				@click="handleClickBtn(social.name)"
			>
				<NuxtIcon :name="`24/${social.icon}`" filled />
				<AText v-if="theme === 'light'" :modifiers="['uppercase']">{{ t("With") }} {{ social.name }}</AText>
			</AButton>
		</a>
	</div>
</template>

<style lang="scss" scoped>
.socials {
	display: flex;
	gap: 8px;

	> * {
		flex: 1;
	}

	&.light {
		@include media-breakpoint-down(lg) {
			flex-direction: column;
		}
	}
}

.social {
	display: inline-flex;
	gap: 4px;
	width: 100%;

	&:deep(svg) {
		transform: translateY(-1px);
		margin: 0;
		font-size: 24px;
	}

	&.social-fb:deep(path) {
		fill: var(--secondary-50);
	}
}

.light .social {
	padding: 12px 16px;
	background: var(--neutral-100);
	border-color: currentColor;
	color: var(--secondary-50);
	transition: background 0.3s;
	position: relative;

	&::before {
		content: "";
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		opacity: 0;
		transition:
			background-color 0.3s cubic-bezier(0.25, 0.8, 0.5, 1),
			opacity 0.6s cubic-bezier(0.25, 0.8, 0.5, 1);
	}

	&:hover::before {
		background-color: currentColor;
		opacity: 0.1;
	}
}

.dark .social {
	padding: 13px 16px;
	border: 1px solid var(--secondary-50);
	background: var(--secondary-15);

	&:hover {
		background: var(--secondary-25);
	}
}
</style>
